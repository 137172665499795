import React, {Component} from "react";
import {Backdrop, Box, CircularProgress} from "@mui/material";
import {withStyles} from "@mui/styles";
import {
	DialogBarcodeReader as DialogBarcodeReaderComponent,
	DialogCreateApplication as DialogCreateApplicationComponent,
	DialogRegistrationUser as DialogRegistrationUserComponent,
	Filter as FilterComponent,
	TableApplications as TableApplicationsComponent
} from "./components";
import agent from "../../../agent/agent";
import {Notification, notificationTypes} from "../../../common/Notification";
import moment from "moment";
import queryString from "query-string";
import {compose} from "recompose";
import {connect} from "react-redux";


const initFilter = {
	search: "",
	status: "",

	filterID: "",
	filterProfile: "",

	dateFrom: null,
	dateTo: null,
}

class Applications extends Component {
	constructor(props) {
		super(props);

		this.state = {
			applications: [],
			initApplications: [],

			filter: {...initFilter},
			pagination: {
				page: 1,
				totalPage: 1
			},

			dateTimeActualGetData: null,

			isLoad: true,
			isShowBackdrop: false,
		};

		this.refDialogCreateApplication = React.createRef();
		this.refDialogBarcodeReader = React.createRef();
		this.refDialogRegistrationUser = React.createRef();
	}

	componentDidMount = async () => {
		await this.initFilter();
		await this.getApplications();
		this.scrollToSelectedApplication();
	}

	scrollToSelectedApplication = () => {
		const appId = this.props.global.chosenApplicationId;
		const el = document.querySelector(`#${appId}`);
		if (el) {
			el.scrollIntoView({behavior: 'smooth'});
		}
	}

	initFilter = async () => {
		const locationSearch = this.props?.location?.search || "";
		let parseSearch = queryString.parse(locationSearch, {
			arrayFormat: "bracket"
		});
		const page = parseSearch.page || 1;

		delete parseSearch.page;

		let filter = {
			...initFilter,
			...parseSearch
		};

		let pagination = {
			...this.state.pagination,
			page: page
		};

		await this.setState({
			filter,
			pagination,
		});
	}


	getApplications = async () => {
		this.setState({isLoad: true})
		const filter = this.getFilters();
		let {
			orders,
			count
		} = await agent.get(`/orders/mechanic${filter}&limit=${20}`)
			.then((res) => res.data)
			.catch((err) => {
				return {
					order: [],
					count: 0,
				}
			})
		let newOrders = [];

		// Получаем всех механиков причастных к заказу и возвращаем в теле заказа
		newOrders = orders.map(order => {
			const otherMechanics = [order.mechanic];
			const orderObjectKeys = Object.keys(order);

			orderObjectKeys.forEach(key => {
				if (['orderServices', 'recommendationsForClient'].includes(key)) {
					if (order[key].length > 0) {
						order[key].forEach(item => {
							let itemObjectKeys = Object.keys(item);

							if (itemObjectKeys.includes('mechanic')) {
								if (otherMechanics.some(mechanic => mechanic.id === item.mechanic.id)) {
									return;
								}

								return otherMechanics.push(item.mechanic);
							}
						});
					}
				}
			});

			return {
				...order,
				otherMechanics,
			};
		});

		const pagination = {...this.state.pagination};
		pagination.totalPage = Math.ceil(count / 20) || 1;

		await this.setState({
			applications: newOrders,
			initApplications: newOrders,
			pagination,
			isLoad: false,
			isShowBackdrop: false,
			dateTimeActualGetData: moment().format('DD.MM.YYYY HH:mm:ss')
		});
		document.body.scrollTo({
			top: 0,
		});
	}

	getFilters = () => {
		const filter = {...this.state.filter};
		const pagination = {...this.state.pagination};

		let string = [
			`page=${pagination.page}`
		];
		Object.keys(filter).map((key) => {
			if (filter[key] === 'ALL') {
				return;
			}
			if (filter[key]) {
				let value = filter[key];

				if (['dateFrom'].includes(key)) {
					value = moment(value).set({h: 0, m: 0, s: 0}).toISOString()
				}
				if (['dateTo'].includes(key)) {
					value = moment(value).set({h: 23, m: 59, s: 59}).toISOString()
				}

				string.push(`${key}=${value}`);
			}
		});

		window.history.replaceState(null, null, `/applications?${string.join("&")}`);

		return `?${string.join("&")}`
	}

	changeFilter = async (filter) => {
		let pagination = {...this.state.pagination};
		pagination.page = 1;

		await this.setState({filter, pagination});
		await this.getApplications();
	}

	changePagination = async (pagination) => {
		await this.setState({pagination});
		await this.getApplications();
	}

	resetFilter = async () => {
		let pagination = {...this.state.pagination};
		pagination.page = 1;
		await this.setState({
			filter: {...initFilter},
			pagination
		})
		await this.getApplications();
	}

	createApplication = async (form = null) => {
		if (form === null) {
			this.refDialogCreateApplication.current.open({
				onSubmit: this.createApplication.bind(this)
			})

			return
		}

		this.setState({isShowBackdrop: true})

		// Проверка на пользователя / регистрация
		const checkUserPhone = await agent.get(`/user/mechanic/client-by-phone?phone=7${form.clientPhoneNumber}`).then((res) => {
			return res.data?.client
		}).catch((err) => {
			return {error: true}
		})
		if (checkUserPhone.error) {
			this.setState({isShowBackdrop: false})

			this.refDialogRegistrationUser.current.open({
				phone: `7${form.clientPhoneNumber}`,
				onSubmit: this.createApplication.bind(this, form)
			})

			return
		}

		// Создание заявки
		const createOrder = await agent.post(`/orders/mechanic`, {
			info: form.applicationName,
			clientId: checkUserPhone.id
		}).then((res) => {
			return res.data?.order
		}).catch((err) => {
			return {error: err.response}
		})
		if (createOrder.error) {
			this.setState({isShowBackdrop: false})

			Notification({
				message: createOrder.error?.data?.details || "Ошибка сервера",
				type: notificationTypes.error
			})

			return
		}

		// // Добавление параметров в зявку
		// const serviceOrder = await agent.post(`/orders/mechanic/${ createOrder.id }/services`, {
		// 	"description": form.applicationDescription,
		// 	"cost": Number.parseFloat(form.applicationPrice),
		// 	"estimates": (form.partsList || []).map((t) => {
		// 		return {
		// 			"description": t.name,
		// 			"partsCost": Number.parseFloat(t.price)
		// 		}
		// 	})
		// }).then((res) => {
		// 	return res.data
		// }).catch((err) => {
		// 	return { error: err.response }
		// })
		// if (serviceOrder.error) {
		// 	this.setState({ isShowBackdrop: false })
		//
		// 	Notification({
		// 		message: serviceOrder.error?.data?.details || "Ошибка сервера",
		// 		type: notificationTypes.error
		// 	})
		//
		// 	return
		// }

		await this.getApplications();
		this.refDialogCreateApplication.current.close();
		this.setState({isShowBackdrop: false})
		Notification({
			message: "Заявка успешно создана",
			type: notificationTypes.success
		})

		this.props.history.push(`/applications/${createOrder.id}`);
	}
	createApplicationWithBarcode = async (form = null) => {
		if (form === null) {
			this.refDialogBarcodeReader.current.open({
				onSubmit: this.createApplicationWithBarcode.bind(this)
			});

			return
		}

		if (await this.checkOrderByBarcode(form.code, false)) {
			return null;
		}

		this.setState({isShowBackdrop: true});

		const createOrder = await agent.post(`/orders/mechanic/by-barcode`, form)
			.then(res => res.data)
			.catch(err => {
				return {error: err.response}
			});

		if (createOrder.error) {
			this.setState({isShowBackdrop: false})

			Notification({
				message: createOrder.error?.data?.details || "Ошибка сервера",
				type: notificationTypes.error
			})

			return
		}

		const application = await agent.get(`/orders/mechanic/${createOrder?.order?.id}`).then((res) => {
			return res.data?.order
		}).catch((err) => {
			return null
		});

		await this.getApplications();
		this.refDialogBarcodeReader.current.close();
		this.setState({isShowBackdrop: false})

		if (!application?.clientId) {
			Notification({
				message: `Запущена синхронизация заявки №${application.id}, пожалуйста ожидайте.`,
				type: notificationTypes.warning
			});
			return
		}

		Notification({
			message: "Заявка по штрих-коду успешно создана, ожидайте установки клиента",
			type: notificationTypes.success,
		});

		this.props.history.push(`/applications/${createOrder.order.id}`);
	};
	updateApplication = async (application) => {
		this.setState({isShowBackdrop: true})

		const responseUpdateApplication = await agent.get(`/orders/mechanic/by-barcode/${application.tricepsBarcode}`).then((res) => {
			return res.data?.order
		}).catch((err) => {
			return null
		});
		if (!responseUpdateApplication) {
			this.setState({isShowBackdrop: false});

			Notification({
				message: "Заказ был удален, поскольку не смог синхронизировать данные.",
				type: notificationTypes.error
			})

			return
		}

		await this.getApplications();
	}

	checkOrderByBarcode = async (barcode, showBackdrop = true, showNotification = false) => {
		if (showBackdrop) {
			this.setState({isShowBackdrop: true});
		}

		const response = await agent.get(`/orders/mechanic/by-barcode/${barcode}`)
			.then(res => res.data.order)
			.catch(err => {
				return {};
			});

		if (Object.keys(response).length > 0) {
			if (showBackdrop) {
				this.setState({isShowBackdrop: false})
			}
			this.props.history.push(`/applications/${response.id}`);

			Notification({
				message: "Заказ с этим штрих-кодом уже существует",
				type: notificationTypes.success,
			});

			return true;
		}

		if (showBackdrop) {
			this.setState({isShowBackdrop: false});
		}

		if (showNotification) {
			Notification({
				message: "Заказа с этим штрих-кодом не существует, можете создать новый",
				type: notificationTypes.success,
			});
		}

		return false;
	};

	render() {
		const {
			classes
		} = this.props;
		const {
			applications,
			pagination,
			filter,
			dateTimeActualGetData,

			isLoad,
			isShowBackdrop
		} = this.state;

		return (
			<>

				<FilterComponent
					initFilter={filter}

					onChange={this.changeFilter}
					onReset={this.resetFilter}
				/>

				<Box className={classes.root}>

					<TableApplicationsComponent
						data={applications}
						pagination={pagination}
						isLoad={isLoad}
						dateTimeActualGetData={dateTimeActualGetData}

						onGetApplications={this.getApplications}
						onUpdateApplication={this.updateApplication}
						onCreateApplication={this.createApplication.bind(this, null)}
						onCreateApplicationWithBarcode={this.createApplicationWithBarcode.bind(this, null)}
						onChangePagination={this.changePagination}
					/>


					<DialogCreateApplicationComponent
						ref={this.refDialogCreateApplication}
					/>
					<DialogBarcodeReaderComponent
						ref={this.refDialogBarcodeReader}
						onCheckOrderByBarcode={this.checkOrderByBarcode}
					/>
					<DialogRegistrationUserComponent
						ref={this.refDialogRegistrationUser}
					/>


					<Backdrop open={isShowBackdrop}>
						<CircularProgress/>
					</Backdrop>
				</Box>

			</>
		);
	}
}

const styles = {
	root: {
		padding: 20,
		boxSizing: "border-box",
		backgroundColor: "white",
		borderRadius: 14,
		border: "1px solid #F0F0F0",

		"@media(max-width: 1199px)": {
			padding: 0,
			borderRadius: 0,
			border: "none",
			backgroundColor: "transparent",
		}
	}
}
Applications = withStyles(styles)(Applications)

export default compose(
	connect(
		state => ({
			global: state.global
		})
	)
)(Applications);

