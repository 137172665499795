import React from "react";
import {
	Box,
	Grid,
	Button,
	Tooltip,
	Skeleton,
	Checkbox,
	Typography,
	Pagination,
	IconButton,

	Table,
	TableRow,
	TableHead,
	TableBody,
	TableCell,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Autorenew as AutorenewIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
	KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import {
	convertNewlinesToBreaks
} from "../../../../../helper/convertor";
import {
	useHistory
} from "react-router-dom";
import moment from "moment";
import orderStatus from "../../../../../constants/orderStatus";
import clsx from "clsx";
import {useSelector} from "react-redux";


const TableApplications = (props) => {
	const {
		data,
		isLoad,
		pagination,

		onGetApplications,
		onChangePagination,
		onUpdateApplication,
		onCreateApplicationWithBarcode,

	} = props;
	const classes = useStyles();
	const innerWidth = window?.innerWidth;
	const ComponentItems = Boolean(innerWidth < 1200) ? MbVersion : PcVersion;

	const handleChangePage = (event, newPage) => {
		let newPagination = { ...pagination };
		newPagination.page = newPage;
		onChangePagination( newPagination );
	}

	return (
		<>

			<Box id="table-application-head" mb={4}>
				<Grid container justifyContent="space-between" spacing={2}>
					<Grid item>
						<Typography className={classes.dateTimeActualGetData}>
							Данные актуальны<br/>на <span>{props.dateTimeActualGetData}</span>
						</Typography>
					</Grid>
					<Grid item>
						<Grid container spacing={1}>
							<Grid item>
								<Button
									className={classes.customBtn}
									variant="contained"
									color="primary"
									size="small"
									fullWidth
									onClick={ onCreateApplicationWithBarcode }
								>Создать/Найти РК</Button>
							</Grid>
							<Grid item>
								<Button
									variant="contained"
									color="primary"
									size="small"
									fullWidth
									onClick={ onGetApplications }
								>Обновить данные</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>

			<ComponentItems
				data={data}
				isLoad={isLoad}
				onUpdateApplication={onUpdateApplication}
			/>

			<Box mt={4}>
				<Grid container justifyContent="center">
					<Grid item>
						<Pagination
							page={Number.parseFloat(pagination?.page || 1)}
							count={Number.parseFloat(pagination?.totalPage || 1)}
							onChange={handleChangePage}
						/>
					</Grid>
				</Grid>
			</Box>

		</>
	)
};

const MbVersion = (props) => {
	const {
		data,
		isLoad,

		onUpdateApplication
	} = props;
	const classes = useStyles();

	return (
		<Grid container spacing={2}>
			<VisibleContent visible={!isLoad}>
				{(data || []).map((item) => (
					<MbVersionRow
						key={`MbVersionRow-${ item.id }`}
						item={item}

						onUpdateApplication={onUpdateApplication}
					/>
				))}
			</VisibleContent>
			<VisibleContent visible={isLoad}>
				<MbVersionLoading/>
			</VisibleContent>
		</Grid>
	)
};
const MbVersionRow = (props) => {
	const {
		item,
		onUpdateApplication
	} = props;
	const history = useHistory();
	const classes = useStyles();
	const refElement = React.createRef();
	const isShowApdateApplication = Boolean(Object.keys(item?.client || {}).length <= 0);

	const [isOpenRow, setOpenRow] = React.useState(false);
	const handleChangeOpenRow = (event) => {
		event.preventDefault();
		event.stopPropagation();

		setOpenRow(!isOpenRow);
	}

	const [isEffectScrollBack, setEffectScrollBack] = React.useState(false);
	const changeEffectScrollBack = () => {
		const saveLastApplication = localStorage.getItem('save-last-application');
		if (!saveLastApplication) {
			return
		}

		if (String(item.id) !== String(saveLastApplication)) {
			return;
		}

		const scrollBody = document.body;
		const currentScroll = scrollBody.scrollTop || 0;
		const scrollTo = refElement.current.getBoundingClientRect();
		setTimeout(() => {
			scrollBody.scrollTo({
				left: 0,
				behavior: "smooth",
				top: (scrollTo?.top || 0) + currentScroll - 120
			});
		}, 100);

		setEffectScrollBack(true);
		setTimeout(() => {
			setEffectScrollBack(false);
		}, 1500);

		localStorage.removeItem('save-last-application')
	};
	React.useEffect(() => {
		changeEffectScrollBack();
	}, []);

	const handleUpdateApplication = (event) => {
		event.preventDefault();
		event.stopPropagation();
		onUpdateApplication(item);
	}

	const handleRouteApplication = () => {
		if (isShowApdateApplication) {
			return null
		}
		localStorage.setItem('save-last-application', item.id);
		history.push(`/applications/${ item.id }`);
	}

	const _clientName = () => {
		if (item?.client?.isLegal) {
			return item?.client?.companyName || 'Н/Д'
		}

		return [
			item?.client?.lastName || '',
			item?.client?.firstName || '',
			item?.client?.patronymic || '',
		].filter((t) => !!t).join(' ') || 'Н/Д'
	};
	const _mechanicName = (customItem) => {
		const findItem = customItem || item;
		const name = [findItem?.mechanic?.lastName, findItem?.mechanic?.firstName, findItem?.mechanic?.patronymic]
			.filter((t) => !!t).join(' ');

		return name || 'Н/Д'
	};
	const _mileage = () => {
		const mileages = item?.mileages || [];
		return mileages[mileages.length - 1]?.value || "Н/Д"
	};
	const _orderServicesMessage = () => {
		let _message = (item?.orderServices || [])
			.filter((t) => t.isDone)
			.map((t) => {
				const mechanic = _mechanicName({mechanic: t?.mechanic});
				const message = t?.description || '';

				return [
					mechanic && `<span>От механика - ${mechanic}</span>`,
					message && `Рекомендация:<br/>${message}`
				].join('<br/>');
			})
			.filter((t) => !!t)
			.join('<br/><br/>');
		return _message || 'Н/Д'
	}
	const _recommendationsForClient = () => {
		let _message = (item?.recommendationsForClient || [])
			.map((t) => {
				const mechanic = _mechanicName({mechanic: t?.mechanic});
				const message = t?.value || '';

				return [
					mechanic && `<span>Рекомендация от механика (${mechanic}):</span>`,
					message && `${message}`
				].join('<br/>');
			})
			.filter((t) => !!t)
			.join('<br/><br/>');
		return _message || 'Н/Д'
	}

	return (
		<Grid item xs={12}>
			<Box
				ref={refElement}
				className={clsx({
					[classes.mobileCard]: true,
					'--back-effect': isEffectScrollBack
				})}
				onClick={handleRouteApplication}
			>
				<Box className={classes.mobileCardHead}>
					Номер: <span>{item?.tricepsOrderId || "Н/Д"}</span>
					<span className="status" style={{background: orderStatus[item.status]?.color}}/>
				</Box>
				<div className={classes.mobileCardDivider}/>
				<Box className={classes.mobileCardBody}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<div className={classes.tableOtherTitle}>Клиент:</div>
							<div className={classes.tableOtherValue}>{_clientName()}</div>
						</Grid>
						<Grid item xs={12}>
							<div className={classes.tableOtherTitle}>Исполнитель:</div>
							<div className={classes.tableOtherValue}>{_mechanicName()}</div>
						</Grid>
						<Grid item xs={6}>
							<div className={classes.tableOtherTitle}>Дата обновления:</div>
							<div className={classes.tableOtherValue}>
								{moment(item.updatedAt).format('DD.MM.YYYY HH:mm:ss')}
							</div>
						</Grid>
						<Grid item xs={6}>
							<div className={classes.tableOtherTitle} style={{ textAlign: "right" }}>Дата создания:</div>
							<div className={classes.tableOtherValue} style={{ textAlign: "right" }}>
								{moment(item.createdAt).format('DD.MM.YYYY HH:mm:ss')}
							</div>
						</Grid>
					</Grid>
				</Box>
				<VisibleContent visible={isOpenRow}>
					<div className={classes.mobileCardDivider}/>
					<Box className={classes.mobileCardFooter}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<div className={classes.tableOtherTitle}>Статус:</div>
								<div className={classes.tableOtherValue}>
								<span style={{color: orderStatus[item.status]?.color}}>
					{orderStatus[item.status]?.label || 'Н/Д'}
				</span>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div className={classes.tableOtherTitle}>VIN:</div>
								<div className={classes.tableOtherValue}>{item?.vin || 'Н/Д'}</div>
							</Grid>
							<Grid item xs={12}>
								<div className={classes.tableOtherTitle}>Пробег:</div>
								<div className={classes.tableOtherValue}>{_mileage()}</div>
							</Grid>
							<Grid item xs={12}>
								<div className={classes.tableOtherTitle}>Причина обращения:</div>
								<div className={classes.tableOtherValue}>
									<div dangerouslySetInnerHTML={{
										__html: convertNewlinesToBreaks(item?.reasonForContacting || 'Н/Д')
									}}/>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div className={classes.tableOtherTitle}>Перечень выполненных работ:</div>
								<div className={classes.tableOtherValue}>
									<div dangerouslySetInnerHTML={{
										__html: convertNewlinesToBreaks(_orderServicesMessage())
									}}/>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div className={classes.tableOtherTitle}>Перечень рекомендаций клиенту:</div>
								<div className={classes.tableOtherValue}>
									<div dangerouslySetInnerHTML={{
										__html: convertNewlinesToBreaks(_recommendationsForClient())
									}}/>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div className={classes.tableOtherTitle}>Доп. сообщение: </div>
								<div className={classes.tableOtherValue}>
									<div dangerouslySetInnerHTML={{
										__html: convertNewlinesToBreaks(item.info || 'Н/Д')
									}}/>
								</div>
							</Grid>
						</Grid>
					</Box>
				</VisibleContent>
				<div className={classes.mobileCardDivider}/>
				<Box className={classes.mobileCardOther}>
					<Grid container spacing={1}>
						<VisibleContent visible={Boolean(isShowApdateApplication)}>
							<Grid item xs={12}>
								<Button
									variant="contained"
									fullWidth
									onClick={handleUpdateApplication}
								>
									Обновить заявку
								</Button>
							</Grid>
						</VisibleContent>
						<Grid item xs={12}>
							<Button
								variant="outlined"
								fullWidth
								onClick={handleChangeOpenRow}
							>
								{isOpenRow ? 'Свернуть доп. информация' : 'Развернуть доп. информация'}
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Button
								variant="contained"
								fullWidth
								onClick={handleRouteApplication}
							>
								Открыть заявку
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Grid>
	)
};
const MbVersionLoading = () => {
	return new Array(2).fill(1).map(() => (
		<Grid item xs={12}>
			<Box>
				<Skeleton
					sx={{
						height: 240,
						transform: "initial"
					}}
				/>
			</Box>
		</Grid>
	))
}

const PcVersion = (props) => {
	const {
		data,
		isLoad,

		onUpdateApplication
	} = props;
	const classes = useStyles();
	const userRole = useSelector((state) => state?.global?.user?.role);

	return (
		<Table className={classes.table}>
			<TableHead>
				<TableRow>
					<TableCell width={40}></TableCell>
					<TableCell>Номер</TableCell>
					<TableCell>Клиент</TableCell>
					<TableCell>Исполнитель</TableCell>
					<TableCell>VIN</TableCell>
					<TableCell>Пробег</TableCell>
					<TableCell>Статус</TableCell>
					<TableCell>Доп. сообщение</TableCell>
					<VisibleContent visible={['admin', 'consultant'].includes(userRole)}>
						<TableCell width={40} align="center">Отред. консул.</TableCell>
					</VisibleContent>
					<TableCell align="right">Дата обновления</TableCell>
					<TableCell align="right">Дата создания</TableCell>
					<TableCell/>
				</TableRow>
			</TableHead>
			<TableBody>
				<VisibleContent visible={!isLoad}>
					{(data || []).map((item) => (
						<PcVersionRow
							key={`PcVersionRow-${ item.id }`}
							item={item}
							userRole={userRole}

							onUpdateApplication={onUpdateApplication}
						/>
					))}
				</VisibleContent>
				<VisibleContent visible={isLoad}>
					<PcVersionLoading userRole={userRole}/>
				</VisibleContent>
			</TableBody>
		</Table>
	)
};
const PcVersionRow = (props) => {
	const {
    item,
		userRole,
		onUpdateApplication
	} = props;
	const history = useHistory();
	const classes = useStyles();
	const isChangedManager = Boolean(item?.isChangedManager);
	const isShowApdateApplication = Boolean(Object.keys(item?.client || {}).length <= 0);
	const refTableRow = React.createRef();

	const [isOpenRow, setOpenRow] = React.useState(false);
	const KeyboardArrowIcon = Boolean(isOpenRow) ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;
	const handleChangeOpenRow = (event) => {
		event.preventDefault();
		event.stopPropagation();

		setOpenRow(!isOpenRow);
	}

	const [isEffectScrollBack, setEffectScrollBack] = React.useState(false);
	const changeEffectScrollBack = () => {
		const saveLastApplication = localStorage.getItem('save-last-application');
		if (!saveLastApplication) {
			return
		}

		if (String(item.id) !== String(saveLastApplication)) {
			return;
		}

		const scrollBody = document.body;
		const currentScroll = scrollBody.scrollTop || 0;
		const scrollTo = refTableRow.current.getBoundingClientRect();
		setTimeout(() => {
			scrollBody.scrollTo({
				left: 0,
				behavior: "smooth",
				top: (scrollTo?.top || 0) + currentScroll - 120
			});
		}, 100);

		setEffectScrollBack(true);
		setTimeout(() => {
			setEffectScrollBack(false);
		}, 1500);

		localStorage.removeItem('save-last-application')
	};
	React.useEffect(() => {
		changeEffectScrollBack();
	}, []);

	const handleUpdateApplication = (event) => {
		event.preventDefault();
		event.stopPropagation();
		onUpdateApplication(item);
	}

	const handleRouteApplication = () => {
		if (isShowApdateApplication) {
			return null
		}
		localStorage.setItem('save-last-application', item.id);
		history.push(`/applications/${ item.id }`);
	}

	const _clientName = () => {
		if (item?.client?.isLegal) {
			return item?.client?.companyName || 'Н/Д'
		}

		return [
			item?.client?.lastName || '',
			item?.client?.firstName || '',
			item?.client?.patronymic || '',
		].filter((t) => !!t).join(' ') || 'Н/Д'
	};
	const _mechanicName = (customItem) => {
		const findItem = customItem || item;
		const name = [findItem?.mechanic?.lastName, findItem?.mechanic?.firstName, findItem?.mechanic?.patronymic]
			.filter((t) => !!t).join(' ');

		return name || 'Н/Д'
	};
	const _mileage = () => {
		const mileages = item?.mileages || [];
		return mileages[mileages.length - 1]?.value || "Н/Д"
	};
	const _orderServicesMessage = () => {
		let _message = (item?.orderServices || [])
			.filter((t) => t.isDone)
			.map((t) => {
				const mechanic = _mechanicName({mechanic: t?.mechanic});
				const message = t?.description || '';

				return [
					mechanic && `<span>От механика - ${mechanic}</span>`,
					message && `Рекомендация:<br/>${message}`
				].join('<br/>');
			})
			.filter((t) => !!t)
			.join('<br/><br/>');
		return _message || 'Н/Д'
	}
	const _recommendationsForClient = () => {
		let _message = (item?.recommendationsForClient || [])
			.map((t) => {
				const mechanic = _mechanicName({mechanic: t?.mechanic});
				const message = t?.value || '';

				return [
					mechanic && `<span>Рекомендация от механика (${mechanic}):</span>`,
					message && `${message}`
				].join('<br/>');
			})
			.filter((t) => !!t)
			.join('<br/><br/>');
		return _message || 'Н/Д'
	}

	return (
		<>
			<TableRow
				ref={refTableRow}
				className={clsx({
					'--open': isOpenRow,
					'--back-effect': isEffectScrollBack
				})}
				onClick={handleRouteApplication}
			>
				<TableCell>
					<IconButton onClick={handleChangeOpenRow}>
						<KeyboardArrowIcon/>
					</IconButton>
				</TableCell>
				<TableCell>{ item.tricepsOrderId || 'Н/Д' }</TableCell>
				<TableCell>{ _clientName() }</TableCell>
				<TableCell>{ _mechanicName() }</TableCell>
				<TableCell>{ item?.vin || 'Н/Д' }</TableCell>
				<TableCell>{ _mileage() }</TableCell>
				<TableCell>
				<span style={{ color: orderStatus[item.status]?.color }}>
					{ orderStatus[item.status]?.label || 'Н/Д' }
				</span>
				</TableCell>
				<TableCell>
					<Box className={classes.tableTextInfo}>
						{ item.info || 'Н/Д' }
					</Box>
				</TableCell>
				<VisibleContent visible={['admin', 'consultant'].includes(userRole)}>
					<TableCell align="center">
						<Checkbox
							checked={Boolean(isChangedManager)}
						/>
					</TableCell>
				</VisibleContent>
				<TableCell align="right">
					{moment(item.updatedAt).format('DD.MM.YYYY HH:mm:ss')}
				</TableCell>
				<TableCell align="right">
					{moment(item.createdAt).format('DD.MM.YYYY HH:mm:ss')}
				</TableCell>
				<TableCell align="right">
					<VisibleContent visible={isShowApdateApplication}>
						<Tooltip title="Обновить заявку" arrow>
							<IconButton onClick={handleUpdateApplication}>
								<AutorenewIcon/>
							</IconButton>
						</Tooltip>
					</VisibleContent>
				</TableCell>
			</TableRow>
			<VisibleContent visible={Boolean(isOpenRow)}>
				<TableRow>
					<TableCell colSpan={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<div className={classes.tableOtherTitle}>Причина обращения:</div>
								<div className={classes.tableOtherValue}>
									<div dangerouslySetInnerHTML={{
										__html: convertNewlinesToBreaks(item?.reasonForContacting || 'Н/Д')
									}}/>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div className={classes.tableOtherTitle}>Перечень выполненных работ:</div>
								<div className={classes.tableOtherValue}>
									<div dangerouslySetInnerHTML={{
										__html: convertNewlinesToBreaks(_orderServicesMessage())
									}}/>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div className={classes.tableOtherTitle}>Перечень рекомендаций клиенту:</div>
								<div className={classes.tableOtherValue}>
									<div dangerouslySetInnerHTML={{
										__html: convertNewlinesToBreaks(_recommendationsForClient())
									}}/>
								</div>
							</Grid>
							<Grid item xs={12}>
								<div className={classes.tableOtherTitle}>Доп. сообщение: </div>
								<div className={classes.tableOtherValue}>
									<div dangerouslySetInnerHTML={{
										__html: convertNewlinesToBreaks(item.info || 'Н/Д')
									}}/>
								</div>
							</Grid>
						</Grid>
					</TableCell>
				</TableRow>
			</VisibleContent>
		</>
	)
};
const PcVersionLoading = ({ userRole }) => {
	return new Array(10).fill(1).map(() => (
		<TableRow>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<TableCell><Skeleton/></TableCell>
			<VisibleContent visible={['admin', 'consultant'].includes(userRole)}>
				<TableCell><Skeleton/></TableCell>
			</VisibleContent>
			<TableCell align="right"><Skeleton/></TableCell>
			<TableCell align="right"><Skeleton/></TableCell>
			<TableCell>
				<Skeleton/>
			</TableCell>
		</TableRow>
	))
};

const VisibleContent = ({visible, children}) => {
	if (!visible) {
		return null
	}
	return children
}

const useStyles = makeStyles(() => ({
	dateTimeActualGetData: {
		fontWeight: "600",
		fontSize: "14px",
		lineHeight: "120%",
		letterSpacing: "-0.02em",
		color: "#8698B1",
	},

	table: {
		"& .MuiTableRow-root.--open .MuiTableCell-body": {
			'border-bottom-color': 'transparent'
		},
		"& .MuiTableRow-root.--back-effect .MuiTableCell-root": {
			background: "#DFEDCE",
			"&:first-child": {
				borderRadius: "10px 0 0 10px"
			},
			"&:last-child": {
				borderRadius: "0 10px 10px 0"
			},
		},
		"& .MuiTableHead-root .MuiTableCell-head": {
			fontSize: "12px",
			lineHeight: "100%",
			letterSpacing: "-0.02em",
			color: "#647083",
			fontWeight: "400",
			padding: "8px",
			border: "none"
		},
		"& .MuiTableBody-root .MuiTableCell-body": {
			fontSize: "16px",
			lineHeight: "100%",
			letterSpacing: "-0.02em",
			color: "#152435",
			fontWeight: "400",
			padding: "16px 8px",
			border: "none",
			borderBottom: "1px solid rgba(21, 36, 53, 0.1)"
		},
	},
	tableTextInfo: {
		maxWidth: "220px",
			margin: "0",
			display: "-webkit-box",
			overflow:" hidden",
			"-webkit-line-clamp": "4",
			"-webkit-box-orient": "vertical",
	},
	tableOtherTitle: {
		marginBottom: "4px",
		fontSize: "12px",
		letterSpacing: "-0.02em",
		color: "#647083",
	},
	tableOtherValue: {
		fontSize: "16px",
		lineHeight: "120%",
		letterSpacing: "-0.02em",
		color: "#152435",

		"& span": {
			color: "#647083"
		},
		"@media(max-width: 1199px)": {
			fontSize: "14px",
			lineHeight: "160%",
		}
	},

	mobileCard: {
		padding: "16px",
		boxSizing: "border-box",
		background: "white",
		borderRadius: "16px",

		"&.--back-effect": {
			background: "#DFEDCE",
		}
	},
	mobileCardDivider: {
		margin: "0 -16px",
		height: "1px",
		background: "#152435",
		opacity: 0.1
	},
	mobileCardHead: {
		display: "flex",
		alignItems: "center",

		fontSize: "18px",
		lineHeight: "100%",
		letterSpacing: "-0.02em",
		color: "#152435",
		paddingBottom: "12px",

		"& .status": {
			width: "20px",
			height: "20px",
			borderRadius: "100%",
			marginLeft: "auto"
		},
		"& span": {
			fontWeight: "700",
		}
	},
	mobileCardBody: {
		padding: "12px 0",
		boxSizing: "border-box"
	},
	mobileCardFooter: {
		padding: "12px 0"
	},
	mobileCardOther: {
		paddingTop: "12px",

		"& .MuiButton-root": {
			height: "auto",
			padding: "8px",
			fontSize: "14px",
			lineHeight: "16px"
		}
	},
}));

export default TableApplications
