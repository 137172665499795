import agent from "../agent/agent";
import {
	Notification,
	notificationTypes
} from "../common/Notification";

const SET_USER = "app/SET_USER";
const SET_AUTH = "app/SET_AUTH";
const TOGGLE_SEARCH_PANEL = "app/TOGGLE_SEARCH_PANEL";
const SET_CHOSEN_APPLICATION_ID = "app/SET_CHOSEN_APPLICATION_ID";
const UPDATE_NOTIFICATIONS = "app/UPDATE_NOTIFICATIONS";

const initialState = {
	user: {},
	is_auth: false,
	isShownSearchPanel: false,
	chosenApplicationId: null,

	notifications: [],
};

export function setUser () {
	return async function (dispatch) {
		const accessToken = localStorage.getItem("accessToken");
		if (!accessToken) {
			return null
		}

		let user = await agent.get(`/user/me`).then((res) => {
			return res.data
		}).catch(() => {
			return null
		});
		if (!user) {
			localStorage.removeItem("accessToken");

			return null
		}

		if (user.role !== 'mechanic' && user.role !== 'consultant' && user.role !== 'admin') {
			localStorage.removeItem("accessToken");
			Notification({
				type: notificationTypes.error,
				message: "Вы не являетесь механиком"
			});

			return
		}
		// if (user.role === 'admin') {
		// 	user.role = 'consultant';
		// 	user.isAdmin = true;
		// }

		dispatch({
			type: SET_USER,
			user: {
				id: user.id,
				firstName: user.firstName,
				lastName: user.lastName,
				isActive: true,
				role: user.role,
				isAdmin: user?.isAdmin || false,
			},
		});
		dispatch({
			type: SET_AUTH,
			is_auth: true,
		});
	}
}
export function logoutUser () {
	return async function (dispatch) {
		localStorage.removeItem("accessToken");

		dispatch({
			type: SET_USER,
			user: {},
		});
		dispatch({
			type: SET_AUTH,
			is_auth: false,
		});
	}
}

export function toggleSearchPanel() {
	return {
		type: TOGGLE_SEARCH_PANEL,
	};
}

export function setChosenApplicationId(id) {
	return function (dispatch) {
		dispatch({
			chosenApplicationId: id,
			type: SET_CHOSEN_APPLICATION_ID,
		});
	};
}

export function getNotifications () {
	return async function (dispatch) {
		const accessToken = localStorage.getItem("accessToken");
		if (!accessToken) {
			return null
		}

		const user = await agent.get(`/user/me`).then((res) => {
			return res.data
		}).catch(() => {
			return null
		});
		if (!user) {
			localStorage.removeItem("accessToken");

			return null
		}

		const notifications = user?.notifications || [];
		dispatch({
			type: UPDATE_NOTIFICATIONS,
			notifications
		})
	}
}

export default function AppState(state = initialState, action = {}) {
	switch (action.type) {
		case SET_USER: {
			return {
				...state,
				user: action.user
			}
		}

		case SET_AUTH: {
			return {
				...state,
				is_auth: action.is_auth,
			}
		}

		case TOGGLE_SEARCH_PANEL: {
			return {
				...state,
				isShownSearchPanel: !state.isShownSearchPanel,
			};
		}

		case SET_CHOSEN_APPLICATION_ID: {
			return {
				...state,
				chosenApplicationId: action.chosenApplicationId,
			};
		}

		case UPDATE_NOTIFICATIONS: {
			return {
				...state,
				notifications: action.notifications,
			};
		}

		default:
			return state;
	}
}
