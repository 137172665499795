import React, {Component} from "react";
import {
	Box,
	Tooltip,
	SpeedDial,

	Backdrop,
	CircularProgress
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	PersonAddAltRounded as PersonAddAltRoundedIcon
} from "@mui/icons-material";
import {
	Table as TableComponent,
	Filter as FilterComponent,
	DialogCreateMechanic as DialogCreateMechanicComponent
} from "./components";
import {
	Notification,
	notificationTypes
} from "../../../common/Notification";
import agent from "../../../agent/agent";
import {phoneFormat} from "../../../helper/formater";
import moment from "moment";

const initFilter = {
	userName: "",
	userPhone: "",
	role: "",

	page: 1
}

class Mechanics extends Component {
	constructor(props) {
		super(props);

		this.state = {
			mechanics: [],
			initMechanics: [],

			filter: {...initFilter},

			totalPage: 0,
			totalCount: 0,

			isLoad: true,
			isShowBackdrop: false,
		};

		this.refDialogCreateMechanic = React.createRef();
	}

	componentDidMount = async () => {
		await this.getMechanics();
	}

	getMechanics = async () => {
		const response = await agent.get(`/user/admin/mechanics`).then((res) => {
			return res.data
		}).catch((err) => {
			return []
		})

		const mechanics = (response?.mechanics || [])
			.sort((a, b) => {
			if (a.createdAt > b.createdAt) {
				return 1
			}
			if (a.createdAt < b.createdAt) {
				return -1
			}
			return 0
		})
			.map((item) => {
				return {
					...item,
					searchPhone: String(item?.phone || '').replace(/\D+/g,"").substr(1)
				}
			});

		this.setState({
			mechanics: [],
			initMechanics: mechanics,
			isLoad: false,

			totalCount: mechanics.length,
			totalPage: Math.ceil(mechanics.length/20),
		}, async () => {
			await this.setMechanics();
		})
	}
	setMechanics = async () => {
		const {
			filter,
			initMechanics
		} = this.state;

		const _userPhone = String(filter.userPhone || '').replace(/\D+/g,"").substr(1);
		let list = [...initMechanics]
			.filter((item) => {
				const fullUserName = [item?.firstName, item?.lastName, item?.patronymic].filter(t => !!t).join(" ").toLowerCase();
				const isStatus = Boolean(filter.status) ? item.status === filter.status : true;
				const isUserName = Boolean(filter.userName) ? fullUserName.includes((filter.userName || "").toLowerCase()) : true;
				const isUserPhone = Boolean(_userPhone) ? (item?.searchPhone || "").includes(_userPhone) : true;
				const isRole = Boolean(filter.role) ? Boolean(filter.role === item.role) : true;

				return Boolean(isStatus && isUserName && isUserPhone && isRole)
			});
		const totalCount = list.length;
		const totalPage = Math.ceil(list.length/20);

		const filterPage = filter?.page || 1;
		const startIndexItems = (filterPage - 1) * 20;
		const endIndexItems = ((filterPage - 1) * 20) + 20;
		list = list.splice(startIndexItems, endIndexItems);

		this.setState({
			mechanics: list,
			totalPage,
			totalCount,
		})
	}

	changeFilter = async (filter) => {
		document.body.scrollTo({top: 0});

		await this.setState({
			filter: {
				...filter,
				page: 1
			}
		});
		await this.setMechanics();
	}
	changeFilterPage = async (newPage) => {
		await this.setState({
			filter: {
				...this.state.filter,
				page: newPage
			}
		});
		await this.setMechanics();
	}
	resetFilter = async () => {
		await this.setState({
			filter: {...initFilter}
		})
		await this.setMechanics();
	}

	createMechanic = async (form = null, editForm = null) => {
		if (!form) {
			this.refDialogCreateMechanic.current.open({
				onSubmit: this.createMechanic.bind(this),
				editForm: editForm
			});

			return null
		}

		this.setState({isShowBackdrop: true})

		const response = await agent.post(`/user/admin/mechanics`, form).then((res) => {
			return res.data
		}).catch((err) => {
			return {error: err.response}
		})
		if (response.error) {
			this.setState({isShowBackdrop: false})

			Notification({
				type: notificationTypes.error,
				message: response.error.data?.details || "Ошибка сервера"
			})

			return null
		}

		await this.getMechanics();
		this.refDialogCreateMechanic.current.close();

		Notification({
			type: notificationTypes.success,
			message: "Пользователь успешно добавлен"
		})

		this.setState({isShowBackdrop: false})
	}
	editMechanic = async (form, isSubmit = false) => {
		if (!isSubmit) {
			const editForm = {...form};
			editForm.phone = phoneFormat(form.phone);
			delete editForm.createdAt;
			delete editForm.updatedAt;

			this.refDialogCreateMechanic.current.open({
				onSubmit: this.editMechanic.bind(this),
				editForm: editForm
			});

			return null
		}

		this.setState({ isShowBackdrop: true })

		const response = await agent.put(`/user/admin/${ form.id }`, form).then((res) => {
			return res.data
		}).catch((err) => {
			return { error: err.response }
		})
		if (response.error) {
			this.setState({isShowBackdrop: false})

			Notification({
				type: notificationTypes.error,
				message: response.error.data?.details || "Ошибка сервера"
			})

			return null
		}

		await this.getMechanics();
		this.refDialogCreateMechanic.current.close();

		Notification({
			type: notificationTypes.success,
			message: "Пользователь успешно изменен"
		})

		this.setState({isShowBackdrop: false})
	}

	render() {
		const {
			classes
		} = this.props;
		const {
			mechanics,

			filter,
			totalPage,
			totalCount,

			isLoad,
			isShowBackdrop
		} = this.state;

		return (
			<>

				<FilterComponent
					initFilter={filter}
					onChange={this.changeFilter}
					onReset={this.resetFilter}
				/>

				<Box className={classes.root}>
					<TableComponent
						data={mechanics}
						isLoad={isLoad}
						filter={filter}
						totalPage={totalPage}

						onChange={this.changeFilter}
						onChangePage={this.changeFilterPage}
						onEditMechanic={this.editMechanic}
					/>
				</Box>


				<Tooltip title="Добавить пользователя" arrow>
					<SpeedDial
						ariaLabel="Добавить пользователя"
						sx={{position: 'fixed', bottom: 24, right: 24}}
						icon={<PersonAddAltRoundedIcon/>}
						onClick={this.createMechanic.bind(this, null, null)}
					/>
				</Tooltip>

				<DialogCreateMechanicComponent
					ref={this.refDialogCreateMechanic}
				/>

				<Backdrop open={isShowBackdrop}>
					<CircularProgress></CircularProgress>
				</Backdrop>

			</>
		);
	}
}

const styles = {
	root: {
		padding: 20,
		boxSizing: "border-box",
		backgroundColor: "white",
		borderRadius: 14,
		border: "1px solid #F0F0F0",

		"@media(max-width: 1199px)": {
			padding: 0,
			borderRadius: 0,
			border: "none",
			backgroundColor: "transparent",
		}
	}
}
Mechanics = withStyles(styles)(Mechanics)

export default Mechanics
