export default {
	ALL: {
		color: "#13b9b3",
		label: "Все статусы",
	},

	NEW: {
		color: "#10B879",
		label: "Новый",
		isSystem: true,
		roles: ['mechanic'],
	},
	IN_WORK: {
		color: "#E4003E",
		label: "В работе",
		roles: ['mechanic'],
	},
	REST: {
		color: "#21367F",
		label: "Отдых",
		roles: ['mechanic'],
	},
	WAITING_FOR_SPARE_PARTS: {
		color: "#EEC306",
		label: "Ожидание запчастей",
		roles: ['mechanic'],
	},
	PARTS_ISSUED: {
		color: "#EEC306",
		label: "Запчасти выданы",
		roles: ['consultant', 'admin']
	},
	APPROVE: {
		color: "#A72681",
		label: "На согласование",
		roles: ['mechanic'],
	},
	APPROVED: {
		color: "#039752",
		label: "Согласован",
		roles: ['consultant', 'admin']
	},
	COMPLETED: {
		color: "#039752",
		label: "Выполнен",
		roles: ['mechanic'],
	},
	EXECUTED: {
		color: "#44474C",
		label: "Завершен",
		isSystem: true
	},
	HOLD: {
		color: "#8d8d8d",
		label: "Приостановлен",
		isSystem: true
	},
	CANCELED: {
		color: "#F5386A",
		label: "Отменен",
		isSystem: true
	},

	DRAFT: {
		color: "#8E8E8E",
		label: "Черновик",
		isSystem: true
	},
	PROCESS: {
		color: "#2D4EC2",
		label: "В процессе",
		isSystem: true
	}
}
