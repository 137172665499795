import React from "react";
import {
	Box,
	Grid,
	Button,
	Backdrop,
	TextField,
	Typography,
	CircularProgress,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import {
	FileUploader
} from "../../../../../components";
import {
	Notification,
	notificationTypes
} from "../../../../../common/Notification";
import agent from "../../../../../agent/agent";


const DiagnosticsInfo = (props) => {
	const {
		application,
		isFullDisabled,
		onUpdateApplication
	} = props;
	const [diagnostic, setDiagnostic] = React.useState(application?.diagnostic || null);
	const [diagnosticUrl, setDiagnosticUrl] = React.useState(application?.diagnosticUrl || null);
	const isNotDiagnostic = Boolean(!diagnostic && !diagnosticUrl);

	React.useEffect(() => {
		setDiagnostic(application?.diagnostic || null);
		setDiagnosticUrl(application?.diagnosticUrl || null);
	}, [application]);

	const [isOpenPopUp, setOpenPopUp] = React.useState(false);
	const handleChangeOpenPopUp = (_val) => {
		setOpenPopUp(_val);
	}

	const [isBackdrop, setBackdrop] = React.useState(false);
	const handleSubmitDiagnostic = async ({ type, value }) => {
		setBackdrop(true);

		let error = false;

		if (type === 'file') {
			const formData = new FormData();
			formData.append('file', value);

			const urlAction = `/orders/mechanic/${ application.id }/file`;
			const response = await agent.post(urlAction, formData).then((res) => {
				return res.data
			}).catch((err) => {
				return {error: true}
			});
			if (response?.error) {
				error = true;
			}
		}
		if (type === 'link') {
			const urlAction = `/orders/mechanic/${ application.id }`;
			const response = await agent.put(urlAction, {
				diagnosticUrl: value
			}).then((res) => {
				return res.data
			}).catch((err) => {
				return {error: true}
			});
			if (response?.error) {
				error = true;
			}
		}

		await onUpdateApplication();

		Notification({
			type: error ? notificationTypes.error : notificationTypes.success,
			message: error ? 'Ошибка загрузки диагностики' : 'Диагностика успешно загружена'
		});

		setOpenPopUp(false);
		setBackdrop(false);
	};

	return (
		<Box>
			<Typography variant="h3" textAlign="left" mb={1}>Диагностика</Typography>

			<VisibleContent visible={Boolean(isNotDiagnostic)}>
				<Typography variant="caption" mb={0.5} sx={{justifyContent: "flex-start"}}>Нет диагностического файла</Typography>
				<Button disabled={isFullDisabled} variant="outlined" size="small" onClick={handleChangeOpenPopUp.bind(this, true)}>
					Добавить файл
				</Button>
			</VisibleContent>
			<VisibleContent visible={Boolean(!isNotDiagnostic)}>
				<Grid container spacing={0.5}>
					<Grid item xs={12}>
						<Typography variant="h4">Ссылки на диагностику</Typography>
					</Grid>
					{Boolean(diagnostic?.url) && (
						<Grid item xs={12}>
							<Button
								component="a"
								target="_blank"
								variant="outlined"
								sx={{ height: "auto", padding: "4px 16px", fontSize: 14, lineHeight: "20px" }}
								href={diagnostic?.url}
							>Открыть по ссылке ({ diagnostic?.url })</Button>
						</Grid>
					)}
					{Boolean(diagnosticUrl) && (
						<Grid item xs={12}>
							<Button
								component="a"
								target="_blank"
								variant="outlined"
								sx={{ height: "auto", padding: "4px 16px", fontSize: 14, lineHeight: "20px" }}
								href={diagnosticUrl}
							>Открыть по ссылке ({ diagnosticUrl })</Button>
						</Grid>
					)}
				</Grid>
				<Box mt={2}/>
				<Button variant="outlined" size="small" onClick={handleChangeOpenPopUp.bind(this, true)}>
					Изменить диагностику
				</Button>
			</VisibleContent>

			<VisibleContent visible={isOpenPopUp}>
				<DialogSelectDiagnostic
					onClose={handleChangeOpenPopUp.bind(this, false)}
					onSubmit={handleSubmitDiagnostic}
				/>
			</VisibleContent>


			<Backdrop open={isBackdrop}>
				<CircularProgress/>
			</Backdrop>
		</Box>
	)
};
const DialogSelectDiagnostic = (props) => {
	const {
		onClose,
		onSubmit
	} = props;
	const [type, setType] = React.useState('link');
	const [fileLink, setFileLink] = React.useState('');
	const [fileBlob, setFileBlob] = React.useState(null);

	const handleChangeFileLink = ({ target }) => {
		setFileLink(target?.value || '')
	}
	const handleChangeFileBlob = (event) => {
		const file = event?.target?.value?.file || null;
		setFileBlob(file);
	}

	const handleSubmit = () => {
		const _val = Boolean(type === 'link') ? fileLink : fileBlob;
		onSubmit({
			type,
			value: _val
		})
	}

	return (
		<Dialog
			open
			fullWidth
			maxWidth="md"
			onClose={onClose}
		>
			<DialogTitle>
				<Typography variant="h3" textAlign="left">Загрузка диагностики</Typography>
			</DialogTitle>
			<DialogContent>
				<ToggleButtonGroup
					size="small"
					color="primary"
					value={type}
					exclusive={true}
					onChange={(e, v) => setType(v)}
				>
					<ToggleButton value="link" sx={{height: "auto", padding: "2px 20px", fontSize: 14}}>Ссылку на файл</ToggleButton>
					<ToggleButton value="file" sx={{height: "auto", padding: "2px 20px", fontSize: 14}}>Прикрепить файл</ToggleButton>
				</ToggleButtonGroup>

				<Box mt={2}/>

				<VisibleContent visible={Boolean(type === 'link')}>
					<TextField
						value={fileLink}
						size="small"
						placeholder="Ссылка на файл"
						fullWidth={true}
						onChange={handleChangeFileLink}
					/>
				</VisibleContent>
				<VisibleContent visible={Boolean(type === 'file')}>
					<FileUploader
						value={fileBlob}
						name="files"
						accept={{
							"image/gif": [ ".gif" ],
							"image/jpeg": [ ".jpeg", ".jpg" ],
							"image/png": [ ".png" ],
							"image/webp": [ ".webp" ],

							"video/mpeg": [ ".mpeg" ],
							"video/mp4": [ ".mp4" ],
							"video/ogg": [ ".ogv" ],
							"video/webm": [ ".webm" ],
							"video/x-msvideo": [ ".avi" ],
							"video/3gpp": [ ".3gp" ],
							"video/3gpp2": [ ".3g2" ],
							"video/quicktime": [ ".quicktime" ],

							'application/pdf': ['.pdf'],
						}}

						onChange={handleChangeFileBlob}
						onDeleteWorkFile={handleChangeFileBlob}
					/>
				</VisibleContent>

			</DialogContent>
			<DialogActions>
				<Box px={2} pb={1}>
					<Grid container justifyContent="flex-end" spacing={1}>
						<Grid item>
							<Button variant="outlined" onClick={onClose}>
								Отменить
							</Button>
						</Grid>
						<Grid item>
							<Button variant="contained" onClick={handleSubmit}>
								Загрузить
							</Button>
						</Grid>
					</Grid>
				</Box>
			</DialogActions>
		</Dialog>
	)
};
const VisibleContent = ({ visible, children }) => {
	if (!visible) {
		return null
	}
	return children
}

export default DiagnosticsInfo
